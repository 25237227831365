.circle-outline {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #fff;
  border: 2px solid #3e000c;
  color: #3e000c;
  text-align: center;
  display: inline-block;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
}

.circle-outline + h4 {
  display: inline-block;
  width: 90%;
  margin-left: 1em;
}

.howto-header {
  height: 16.75em;
  width: 100%;
  background-image: url("../../Images/apply-banner.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}

.howto-header .overlay {
  position: absolute;
  height: 16.75em;
  width: 100%;
  background-color: #15283f;
  opacity: 0.5;
}

.howto-header .banner-text-div {
  position: relative;
  width: 100%;
}

.howto-header .banner-text {
  color: #ffffff;
}

.how-to-apply .apply.container {
  margin-top: 3em;
}

.apply.container > div + div {
  margin-top: 0.5em;
}

/* .apply.container div {
    border-bottom: 1px solid #BAC3BF;
    padding-bottom: 1em;
  } */

.apply.container div:last-child {
  border-bottom: 0;
}

.apply.container h4 {
  color: #3e000c;
}

.apply.container h4 + p {
  margin-top: 1em;
  margin-bottom: 1.5em;
  margin-left: 3.5em;
}

.underline.body-bold a {
  text-decoration: none;
  color: #2f323a;
}

.apply-title .title-string {
  font-weight: normal;
  font-size: 21px;
  line-height: 152%;
  margin-bottom: 1em;
}

.apply-title p .underline.body-bold {
  line-height: 152%;
}

/* .how-to-apply .read-more {
    margin-top: 3em;
  } */

.how-to-apply .read-more-row {
  margin-top: 3em;
  margin-bottom: 0.25em;
}

.pointer-one {
  margin-left: 3.5em;
}

.field-container {
  margin-left: 3.5em;
  margin-top: 2em;
}

.field-container .p-dropdown {
  width: 75%;
}

.field-container .p-dropdown-trigger-icon {
  color: #EB7100;
}

.reach-us {
  width: 100%;
  height: 17em;
  position: relative;
  background: rgba(186, 195, 191, 0.3);
  margin-top: 3em;
}

.reach-us .pink-bar {
  width: 6px;
  position: relative;
  display: inline-block;
  background: #ed217c;
  height: 17em;
}

.reach-us .reach-us-content {
  width: 97%;
  float: right;
  padding: 0.75em;
}

.reach-us-content p span {
  display: block;
}

.download-application-button .p-button {
  background-color: #EB7100 !important;
  border: none !important;
  min-height: 3em;
  width: 100%;
  border-radius: 0em;
}

.download-application-button
  .p-button
  .p-component
  .p-button-outlined
  .p-button-help
  .p-disabled:disabled {
  color: rgba(47, 50, 58, 0.6);
  background-color: #bac3bf;
}

.download-application-button .p-button .p-button-label {
  color: black;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.apply-contact-us .contact-us .icon-container {
  left: 29em;
  top: 6.3em;
}

.field-container .body-bold {
  margin-bottom: 1em;
}

.download-application-button span {
  width: 35%;
  display: inline-block;
}

.download-application-button .call-fax {
  margin-left: 2em;
  width: 55%;
  padding-right: 2em;
}

.download-application-button {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.apply-contact .contact-us {
  margin-top: 2em;
  margin-bottom: 2em;
}

.apply-contact .contact-us .contact-content {
  padding: 2.5em;
}

.circle-pointer {
  display: inline-block;
}

.call-fax a {
  text-decoration: none;
  border-bottom: 2px solid #EB7100;
  color: #2f323a !important;
  white-space: nowrap;
}
.table-bordered th,
.table-bordered td {
  border: 2px solid #000 !important;
}

.custom-list-item p {
  margin-left: 5%;
  margin-top: 3%;
}

@media (max-width: 1400px) {
  .download-application-button .p-button {
    min-height: 4.2em;
  }
  .download-application-button .call-fax {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .howto-header {
    background-image: url("../../Images/how-to-apply-phone.svg");
  }
  .download-application-button .p-button {
    min-height: 3em;
  }
  .download-application-button span:first-child {
    width: 80%;
  }
  .download-application-button .call-fax {
    width: 80%;
    margin-left: 0;
    margin-top: 0.7em;
    padding-right: 0;
  }

  .apply.container h4 + p,
  .pointer-one,
  .field-container {
    margin-left: 0.75em;
  }
}

@media (max-width: 570px) {
  .pointer-one {
    margin-left: 0.5em;
  }
  .field-container {
    margin-left: 0.5em;
  }
  .contact-wrap-apply {
    display: block;
  }
}

.table-msg a {
  text-decoration: none;
  border-bottom: 2px solid #EB7100;
  color: #2f323a !important;
  white-space: nowrap;
}
.faq-header {
    height: 16.75em;
    width: 100%;
    background-image: url("../../Images/faq.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.faq-header .overlay {
    position: absolute;
    height: 16.75em;
    width: 100%;
    background-color: #15283F;
    opacity: 0.5;
}

.faq-header .banner-text-div {
    position: relative;
    width: 100%;
}

.faq-header .banner-text {
    color: #FFFFFF;
}

.expand-all {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-left: 2px;
}

.expand-all-box {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
}

.faq-all {
  font-family: Poppins;
  font-size: 25px;
  margin-left: 2px;
  color: #3E000C;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.checkbox1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top: 4em; */
}

.faq-row .row-title.closed .icon-wrapper {
    background-image: url("../../Images/plus-icon.svg");
    width: 12px;
    height: 12px;
}

.faq-row .row-title.expanded .icon-wrapper {
    background-image: url("../../Images/minus-icon.svg");
    width: 12px;
    height: 2px;
}

.faq-row-wrapper .faq-title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    color: #3E000C;
}

.faq-row-wrapper .faq-body .faq-row .row-title .row-title-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2F323A;
    padding-left: 1.5em;
}

.faq-row-wrapper .faq-body .faq-row .row-content {
    background: #F1F3F2;
    margin-left: 2em;
}

.faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
    background: #F1F3F2;
    padding: 1.6em;
    padding-left: 2em;
}

/* .font-face-p .read-more {
    margin-top: 3em;
} */

.font-face-p .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}

.faq-row-wrapper .faq-title {
    border-bottom: 0;
    margin-top: 2em
}

.faq-content a {
    color: #2F323A;
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
}

.faq-content a:hover {
    color: #EB7100;
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
}

.faq-row-wrapper .faq-body .faq-row .row-title.expanded + .row-content {
    margin-bottom: 0.75em;
}

.faq-row-wrapper .faq-body .faq-row .row-title.closed + .row-content {
    margin-bottom: 0;
}

.faq-row-wrapper.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c.faq-title {
    border-bottom: 0;
    margin-top: 2em;
}

.faq-row-wrapper .faq-body .faq-row .row-content {
    background: #F1F3F2;
    margin-left: 1.5em;
    padding-left: 1em
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    padding: 1.6em 1.6em 1.6em 2em !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #2F323A !important;

}

.faq-back-to-top {
    margin-top: 3em;
}

@media(max-width:992px) {
    .faq-header {
        background-image: url("../../Images/faq-phone.svg");
    }
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #828282;
}