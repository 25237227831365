.privacy-content1 h3 {
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    line-height: 32px;
    color: #2F323A;
    /* margin-left: 0.6em; */
    /* padding: 1em 0 0 1em; */
}

.privacy-content1 h2 {
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    font-size: 26px;
    line-height: 32px;
    color: #3E000C;
    margin-top: 1em;
    /* margin-left: 0.6em; */
    /* padding: 1em 0 0 1em; */
}

.privacy-content1 p {
    font-family: Poppins;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    color: #2F323A;
    margin-top: 1em;
    margin-bottom: 1em;
    /* margin: 1em 1em 1em 2.5em; */
}

.privacy-content1 h1 {
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    line-height: 32px;
    color: #3E000C;
    /* margin-left: 1.2em; */
    /* margin-top: 1em; */
}

.privacy-mail-address a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color: #2F323A;
}

.privacy-main-div .footer-content {
    margin-top: 2em;
}

.privacy-back-to-top {
    margin-top: 2em;
    /* margin-left: 2.7em; */
}

.faq-header {
    height: 16.75em;
    width: 100%;
    background-image: url("../../Images/faq.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.faq-header .overlay {
    position: absolute;
    height: 16.75em;
    width: 100%;
    background-color: #15283F;
    opacity: 0.5;
}

.faq-header .banner-text-div {
    position: relative;
    width: 100%;
}

.faq-header .banner-text {
    color: #FFFFFF;
}

.expand-all {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-left: 2px;
}

.expand-all-box {
    width: 24px;
    height: 24px;
    box-sizing: border-box;
}

.checkbox {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 4em;;
}

.faq-row .row-title.closed .icon-wrapper {
    background-image: url("../../Images/plus-icon.svg");
    width: 12px;
    height: 12px;
}

.faq-row .row-title.expanded .icon-wrapper {
    background-image: url("../../Images/minus-icon.svg");
    width: 12px;
    height: 2px;
}

.faq-row-wrapper .faq-title h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    color: #3E000C;
}

.faq-row-wrapper .faq-body .faq-row .row-title .row-title-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2F323A;
    padding-left: 1.5em;
}

.faq-row-wrapper .faq-body .faq-row .row-content {
    background: #F1F3F2;
    margin-left: 2em;
}

.faq-row-wrapper .faq-body .faq-row .row-content .row-content-text {
    background: #F1F3F2;
    padding: 1.6em;
    padding-left: 2em;
}

/* .font-face-p .read-more {
    margin-top: 3em;
} */

.font-face-p .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}

.faq-row-wrapper .faq-title {
    border-bottom: 0;
    margin-top: 2em
}

.faq-content a {
    color: #2F323A;
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
}

.faq-content a:hover {
    color: #EB7100;
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
}

.faq-row-wrapper .faq-body .faq-row .row-title.expanded + .row-content {
    margin-bottom: 0.75em;
}

.faq-row-wrapper .faq-body .faq-row .row-title.closed + .row-content {
    margin-bottom: 0;
}

.faq-row-wrapper.styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c.faq-title {
    border-bottom: 0;
    margin-top: 2em;
}

.faq-row-wrapper .faq-body .faq-row .row-content {
    background: #F1F3F2;
    margin-left: 1.5em;
    padding-left: 1em
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    padding: 1.6em 1.6em 1.6em 2em !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #2F323A !important;

}

.faq-back-to-top {
    margin-top: 3em;
}

@media(max-width:992px) {
    .faq-header {
        background-image: url("../../Images/faq-phone.svg");
    }
}
