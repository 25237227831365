.legal-content h2 {
    font-family: Poppins;
    font-weight: 400;
    font-style: normal;
    font-size: 25px;
    line-height: 32px;
    color: #3E000C;
    margin-left: 0.6em;
    padding: 1em 0 0 1em;
}

.legal-content h1 {
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    line-height: 32px;
    color: #3E000C;
    margin-left: 1.2em;
    margin-top: 1em;
}

.legal-content p {
    font-family: Poppins;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    color: #2F323A;
    margin: 1em 1em 1em 2.5em;
}

.legal-notice-main-div .footer-content {
    margin-top: 2em;
}

.back-to-top {
    margin-left: 2.5em;
}