.eligibility-header {
    height: 16.75em;
    width: 100%;
    background-image: url("../../Images/eligibility-requirements.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.additional-requirements.container > div, .eligible-content-body.container > div {
    margin: 0 auto;
}

.eligible-content-body.container > div {
  padding: 0.5em 0 0.5em 0;
}

/* .additional-requirements.container > div + div, .eligible-content-body.container > div + div {
    margin-top: 1em;
} */

.eligibility-header .overlay {
    position: absolute;
    height: 16.75em;
    width: 100%;
    background-color: #15283F;
    opacity: 0.5;
}

.eligibility-header .banner-text-div {
    position: relative;
    width: 100%;
}

.eligibility-header .banner-text {
    color: #FFFFFF;
}  

.whats-next ul {
    list-style-type: none;
    text-align: center;
    margin-top: 2.5em;
    padding-left: 0;
}


.eligibility-steps h2 {
    color: #3E000C;
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    font-weight: 600;
    font-size: 20px;
}

.eligibility-steps p {
    color: #2F323A;
}

.pink-bar {
    width: 0.7%;
    position: relative;
    float: left;
    background: #ED217C;
    height: 100%;
}

.find-eligibility {
    min-height: 12em;
    position: relative;
    margin-top: 3em;
}
.find-eligibility::after {
    content: "";
    background-color: rgba(186, 195, 191, 0.3);
    background-image: url('../../Images/eligibilityIcon.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 80%;
    opacity: 0.5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}
.contact-us .eligibility-content {
    width: 95%;
    height:100%;
    margin-left: 2.5em;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    padding-right: 7em;
}

.find-eligibility .eligibility-content {
    width: 95%;
    height:100%;
    margin-left: 2.5em;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    padding-right: 7em;
}

/* .contact-us .icon-container {
    position: absolute;
    height: 10em;
    width: 10em;
    background-image: url('../../Images/phone-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 8.2em;
    left: 50.5em;
} */

.find-eligibility h3 {
    color: #3E000C;
    margin-top: 1.5em;
    display: inline-block;
}

.find-eligibility .eligibility-content h2 {
    color: #3E000C;
    margin-top: 0;
    display: block;
    font-weight: 400;
    font-size: 25px;
}

.find-eligibility p, .contact-us p {
    color: #2F323A;
    margin-bottom: 2em;
    margin-top: 1em;
}

.contact-us p {
    margin-top: 0;
    margin-left: 0;
}

.eligibility-steps {
    margin-top: 3em !important;
}

.eligibility-steps a {
    color: #2F323A;
}

.find-eligibility .p-button {
    background-color: #EB7100 !important;
    border: none !important;
    border-radius: 0em;
}

.find-eligibility .p-button .p-button-label {
    color: black;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.not-eligible-content.container h1 {
    color: #15283F;
    text-align: center;
    margin-top: 1em;
}

.not-eligible-dialogue h3 {
    color: #3E000C;
    text-align: left;
}

.not-eligible-dialogue span {
    display: inline-block;
    margin-left: 1em;
    
}

.not-eligible-dialogue {
    min-height: 5em;
    background-color: rgba(62, 0, 12, 0.1);
    text-align: center;
    margin-top: 2em;
}


.more-info {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 32px;
    color: #2F323A;
    margin-top: 3em;
}

.contact-us {
    min-height: 12em;
    position: relative;
}

.contact-us .contact-content {
    width: 95%;
    padding-left: 3em;
    padding-top: 3em;
    padding-bottom: 1em;
    padding-right: 3em;
    margin-right: 0;
    border-left: 6px solid #ED217C;
}

.eligible-content.container h1 {
    color: #15283F;
    text-align: center;
    margin-top: 1em;
}

.eligible-dialogue {
    text-align: center;
    background: rgba(16, 127, 77, 0.1);
    min-height: 6em;
    margin-top: 2.5em;
    display: flex;
}


.eligible-dialogue h3 {
    color: #107F4D;
    text-align: left;
    font-size: 21px;
    margin-right: 4em;
}

.whats-next {
    text-align: center;
    margin-top: 3em;
}

.whats-next h2 {
    color: #3E000C;
    margin-top: 2em;
}
.review {
    color: #3E000C;
}
.if-approved {
    color: #3E000C;
}
.reapply {
    color: #3E000C;
}

/* .eligibility-main-content .read-more {
    margin-top: 3em;
} */

.eligibility-main-content .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}
.eligibility-steps .body {
    margin-top: 1em;
}
.eligibility-steps .body-bold {
    margin-top: 2em;
}

.p-button .p-component .p-button-outlined .p-button-help {
    height: 3em;
    width: 13em;
    border-radius: 0em;
}

.custom-list a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
}

.custom-list a :hover {
   color: #EB7100;
}

/* .not-eligible-main .read-more {
    margin-top: 3em;
} */

.not-eligible-main .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}

.non-eligible-body.container .contact-us {
    margin-top: 3em;
}

.eligible-content-body.container {
    margin-top: 1em;
}

.eligible-content-body.container p h4 {
    color: #3E000C;
}

.custom-list-div .custom-list {
    margin-left: 3.5em;
    margin-top: 1em;
}

.eligible-download-application-button {
    margin-top: 2em;
    margin-left: 3.5em;
}

.eligible-download-application-button .p-button.p-component {
    background-color: #EB7100 !important;
    border: none !important;
    height: 3em;
    width: 100%;
    border-radius: 0em;
}

.eligible-download-application-button .p-button.p-component .p-button-label {
    color: black;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}


.contact-fax p {
    margin-bottom: 0;
}

.contact-fax a {
    color: black;
    border-bottom: 2px solid #EB7100; 
    text-decoration: none;
    white-space: nowrap;
}

.contact-fax .bold-span {
    display: contents;
  }

.doctor-signature {
    margin-left: 3.5em;
    margin-bottom: 1.5em;
    margin-top: 1em;
}

.send-application {
    margin-left: 3.5em;
}

.eligible-dialogue span {
    margin-left: 1em;
}

.review .review-title {
    margin-top: 2em;
}

.if-approved-title {
    margin-top: 0.8em;
}

.reapply .reapply-title {
    margin-top: 1.75em;
}

.whats-next ul li {
    padding: 2em;
}

.custom-list li .highlight-income {
    color: #EB7100;
}

.medicare-eligible-header.container h1 {
    color: #15283F;
    text-align: center;
    margin-top: 1em;
}

.medicare-eligible {
    display: flex;
    text-align: center;
    background: rgba(16, 127, 77, 0.1);
    min-height: 5em;
    margin-top: 2.5em;
}

.medicare-eligible span {
    margin-left: 1em;
}


.medicare-eligible h3 {
    color: #107F4D;
    text-align: left;
    font-size: 21px;
    margin-right: 4em;
    
}

.additional-requirements h4 {
    color: #3E000C;
}

.additional-requirements.container {
    margin-top: 3em;
}

.medicare-eligible-content.container .custom-list {
    margin-bottom: 3em;
}

.medicare-eligible-content .custom-list {
    margin-bottom: 3em;
    margin-top: 1em;
}

.application-details {
    margin-top: 2em;
}

.application-details h4 {
    color: #3E000C;
}

.custom-list-main-div {
    margin-left: 3.75em;
    margin-bottom: 2em;
}

.custom-bullet-main-div .custom-list {
    margin-top: 1em;
}

.custom-list-main-div .custom-list {
    margin-top: 1em;
}

.button-contact {
    margin-left: 3.75em;
    margin-bottom: 2em;
}

.button-contact span {
    width: 35%;
    display: inline-block;
}

.button-contact .p-button {
    background-color: #EB7100 !important;
    border: none !important;
    min-height: 3em;
    width: 100%;
    border-radius: 0em;
}

.button-contact .p-button .p-button-label {
    color: black;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.non-eligible-body.container .more-info a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color: #2F323A;
}


/* .eligible-dialogue .correct-icon {
    margin-top: -2em;
    margin-left: 3em;
} */


.send-application-list .custom-list {
    margin-left: 3.5em;
    margin-top: 1em;
}

.whats-next p {
    margin-top: 1em;
}

.medicare-custom-list .custom-list {
    margin-left: 3.5em;
    margin-top: 1em;
}

.medicare-ask-doctor .application-status {
    margin-left: 3.5em;
    margin-top: 1em;
    margin-bottom: 1.5em;
}

.medicare-call-fax {
    margin-left: 2em;
}

/* .eligible-main-div .read-more, .medicare-eligible-main-div .read-more {
    margin-top: 3em;
} */

.eligible-main-div .read-more-row, .medicare-eligible-main-div .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}


/* .eligible-content-body.container .eligible-download-application-button {
    text-align: center;
} */

.find-eligibility .icon-container {
    position: absolute;
    height: 10em;
    width: 10em;
    background-image: url('../../Images/eligibilityIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 2em;
    opacity: 0.3;
    left: 39.25em;
}

.not-eligible-body .contact-us .icon-container
{  position: absolute;
    height: 10em;
    width: 10em;
    background-image: url('../../Images/phone-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 7em;
    opacity: 0.3;
    left: 50.25em;}

.eligible-content-body.container .contact-us {
    padding: 0;
    margin-bottom: 3em;

}

.additional-requirements.container .contact-us {
    margin-bottom: 3em;
    margin-top: 2.5em;
}

.eligibility-steps .custom-list {
    margin-top: 1em;
}

.contact-us::after {
    content: "";
    background-color: rgba(186, 195, 191, 0.3);
    background-image: url('../../Images/phone-icon.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 50%;
    opacity: 0.5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.button-contact .contact-fax {
    margin-left: 2em;
    width: 55%;
   padding-right: 2em;
}

.send-application-list {
    margin-bottom: 1.5em;
}

.prescription-expenses {
    margin-top: 2em;
}

.eligibility-steps .body-bold a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color:  #2F323A !important;
}

.contact-content a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color:  #2F323A !important;
    white-space: nowrap;
}

@media(max-width:1400px) {
    .button-contact .p-button {
        min-height:4.2em;
    }
    .button-contact .contact-fax {
        width:50%;
    }
    .button-contact .p-button {
        min-height:4.2em;
    }
    .download-application-button .call-fax {
        width:50%;
    }
}

/* @media(max-width:1200px) {
    .eligible-download-application-button .contact-fax {
       display: block;
       margin-top: 1em;
       margin-left: 0;
     }
} */

@media(max-width:1064px) {
    .medicare-eligible h3 {
        text-align: left;
        /* margin-left: 2em; */
        margin-right: 0;
}
    .eligible-dialogue h3 {
        margin-right: 4em;
    }
}

@media(max-width:992px) {
    .eligibility-header {
        background-image: url("../../Images/eligibility-req-phone.svg")
    }
    .find-eligibility::after {
        background-size: auto 30%;
    }
    .contact-us::after {
        background-size: auto 25%;
    }

    .banner-text-div .banner-text {
        font-weight: 300;
        font-size: 28px;
        line-height: 32px;
    }

    .whats-next ul li.col-12 {
        width: 100%;
    }

    .find-eligibility .eligibility-content {
        width: auto;
        padding-right: 2.5em;
    }
    .button-contact .contact-fax {
        width: 100%;
    }

    .button-contact {
        margin-left:0.75em;
    }
    .button-contact .p-button {
        min-height:3em;
    }
    .button-contact span:first-child {
        width:80%;
    }
    .button-contact .contact-fax {
        width:80%;
        margin-left:0;
        margin-top:0.7em;
        padding-right:0;
    }

    .custom-list-main-div, .medicare-ask-doctor .application-status, .medicare-custom-list .custom-list {
        margin-left:0.75em;
    }

    .custom-list-div .custom-list {
        margin-left: 0.75em;
    }

    .doctor-signature {
        margin-left: 1em;
    }

    .send-application-list .custom-list {
        margin-left: 0.75em;
    }

}

@media(max-width:414px)  {
    .button-contact .contact-fax p{
        margin-top: 1em;
     }

     .eligible-download-application-button .contact-fax p{
        font-size: 14px;
        margin-top: 1em;
     }
 
     .button-contact .p-button .p-button-label {
         font-size: 14px;
         line-height: 20px;
     }
 
     .button-contact .p-button {
         width: 75%;
     }
 
     .button-contact span {
         display: contents;
     }
     .custom-list-main-div {
         margin-left: 0;
     }
     .medicare-custom-list .custom-list {
         margin-left: 0;
     }
     .medicare-ask-doctor .application-status {
         margin-left: 0;
     }

     .medicare-eligible h3 {
        text-align: left;
        font-size: 21px;
       margin-right: 0;
    }
    .eligible-dialogue h3 {
        margin-right: 0;
    }
    .custom-list-div .custom-list {
        margin-left: 0;
    }
    .send-application-list .custom-list {
        margin-left: 0;
    }
    .eligible-download-application-button.button-contact {
        margin-left: 0;
    }
}

.custom-list-div {
    margin-bottom: 2em;
}