.footer-content {
    background-color: #15283F;
    min-height: 16em;
    color: #ffffff;
}

.medicine-route-div {
    flex: 0 0 55%;
    padding: 2em 8em 2em 1.5em;
}

.page-route-options ul {
   list-style-type: none;
}

.legal-notice a {
    color: #ffffff;
    text-decoration: none;
}

.legal-notice a:hover {
    color: #EB7100;
    border-bottom: 2px solid #EB7100;
    text-decoration: none;
}

.privacy-policy a {
    color: #ffffff;
    text-decoration: none;
}

.privacy-policy a:hover {
    color: #EB7100;
    border-bottom: 2px solid #EB7100;
    text-decoration: none;
}
.your-privacy-choices a {
    color: #ffffff;
    text-decoration: none;
}
 
.your-privacy-choices a:hover {
    color: #FF8D0A;
    border-bottom: 2px solid #EB7100;
    text-decoration: none;
}

.question-body {
    font-size: 14px;
}

.question-text {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 0.3em;
}

.medicines-list {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}

.medicines-list p {
    font-size: 14px;
}

.question-copyright-div .copy-right {
    font-size: 13px;
    line-height: 16px;
    padding-top: 7em;
}

.page-route li {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
}

.page-route-options .list-group-item {
    color: #ffffff;
    background-color: #15283F;
}

.list-group.list-group-horizontal .list-group-item:first-child {
    padding-left: 0;
}

.footer-row-one {
    padding-top: 2em;
    margin-bottom: 1em;
}

.copy-right, .page-route-options {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
}

.copy-right {
    margin-top: 0.5em;
}


.list-group-item {
    border: 0;
}

.footer-contact-info .question-text a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color: #EB7100;
}


@media(max-width:992px) {
    .question-copyright-div {
        padding: 0.5em;
        text-align: start;
    }
    .medicines-list.col-lg-7.col-sm-12 {
        padding:0.5em;
        text-align:start;
    }
    .copy-right-box {
        padding:0.5em;
        text-align: center;
    }

    .copy-right-privacy {
        display: flex;
        flex-direction: column-reverse;
    }

    ul.page-route.list-group.list-group-horizontal {
        flex-direction: column;
        padding: 0 0.5em;
    }

    ul.page-route.list-group.list-group-horizontal li {
        padding-left:0;
    }

    .page-route-options ul {
        margin-left: 0;
     }
}