.hero {
    position: relative;
    width: 100%;
    min-height: 31em;
    height: auto;
    left: 0;
}

.p-dialog-header-icon:focus {
    box-shadow: 0 0 0 0.2rem #0091FF !important;
}

/* .hero .gradient-container {
    position: absolute;
    width: 54.6em;
    height: 31em;
    left: 0;
    z-index:99;
    background: linear-gradient(90deg, rgba(184, 197, 196, 1), rgba(184, 197, 196, 0));
}

.hero .hero-image {
    position: absolute;
    width: 60%;
    height: 31em;
    right: 0;
    background: url("../../Images/hero-img.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hero .intro-text-container {
    position: absolute;
    width: 34.1em;
    height: 20.5em;
    left: 11.5em;
    margin-top: 5em;
    background: rgba(21, 40, 63, 1);
    color: #ffffff;
    padding-top: 4em;
    padding-left: 3.5em;
    z-index: 200;
}

.hero .intro-text-container p {
    margin-top: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
}

.hero .intro-text-container a {
    width: 13em;
    height: 3em;
    background: rgba(255, 141, 10, 1);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(62, 0, 12, 1);
    text-decoration: none;
    display: inline-block;
    text-align: center;
    padding: 0.75em;
    margin-top: 2em;
}

.hero .linear-gradient {
    position: absolute;
    width: 49.4em;
    height: 31.1em;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #B9BBC2 81.24%, rgba(182, 185, 193, 0) 100.98%);
    z-index: 100;
} */

.whatis-paf {
    padding-top: 3em;
    margin-bottom: 1.5em;
}

.whatis-paf-content .p-button {
    background-color: #EB7100 !important;
    border: none !important;
    height: 48px;
    width: 140px;
    margin-top: 2em;
    border-radius: 0em;
    vertical-align:0%;
}

.whatis-paf-content .p-button .p-button-label {
    color: black;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.home-image {
    position: relative;
    padding-top: 30%;
}

.home-image .home-image-pic {
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.whatis-paf-content {
    padding: 3%;
    padding-left: 4%;
}

.whatis-paf-content .content {
    margin: 0;
    height: auto;
}

.home-options ul {
   list-style-type: none;
   text-align: center;
}

.home-options ul li span {
    display: inline-block;
}

.home-options .option-link {
    color: #2F323A !important;
}

.home-options .option-link:hover {
   color:  #EB7100 !important;
}

.home-read-more {
    margin-top: 0em;
}

.content h2 {
    color: #3E000C;
}

.content p {
    margin-top: 1em;
}

.available-medicines h3 {
    color: #3E000C;
    margin-top: 1.4em;
    margin-bottom: 0.75em;
}

.eligibility h3 {
    color: #3E000C;
    margin-top: 1em;
    margin-bottom: 0.75em;
}

.howto-apply h3 {
    color: #3E000C;
    margin-top: 1em;
    margin-bottom: 0.75em;
}

.option-link {
    border-bottom: 2px solid #EB7100;
    text-decoration: none;
}

.home-options ul li p {
    margin: 0 auto;
    padding-bottom: 1.25em;
}

.home-options {
    margin-bottom: 2em;
}

.dialog-details {
    position: relative;
    width: 50em;
    height: 30em;
    padding-bottom: 10px;
}
.dialog-link {
    color: #495057 !important;
}
.dialog-link:hover {
    color:  #EB7100 !important;
}

.dialog-details h2 {
    font-family: Poppins;
    font-style: normal;
    line-height: 24px;
    font-weight: 600;
    font-size: 20px;
    color: #3E000C;
}

.dialog-details p {
    margin-top: 1em;
}

.popup-custom-list {
    margin-top: 1em;
}

@media (max-width: 570px) {
    .popup-custom-list .custom-list {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: 5em;
    }
}

@media (min-width: 570px) {
    .popup-custom-list .custom-list {
        margin-top: 1em;
        display: grid;
        grid-gap: 20px;
        grid-auto-flow: column;
        grid-template-rows: repeat(2, auto);
        margin-left: 5em;
    }
}
.popup-custom-list .custom-list li + li {
    margin-top: 0;
}

.dialog-interrupter-msg a {
    color: black; 
    border-bottom: 2px solid #EB7100; 
    text-decoration: none;
    white-space: nowrap
}

.p-dialog.p-component .p-dialog-header {
    padding: 10px;
}

.p-dialog.p-component .p-dialog-header-close-icon {
    content: "+";
    font-size: larger;
    font-weight: bold;
    width: 1em;
    height: 1em;
    transform: rotate(-90deg);
    border: none !important;
}

.intro-text-container a:hover {
    color: #2F323A !important;
}

.home-options-row {
    padding: 2em 0;
}

#hero {
    background: #B8BBC4;
}

#hero .img-col {
    background: url("../../Images/homepage-banner.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    overflow: hidden;
    position: absolute;
    width: 70%;
    min-height:100%;
    right:0;
}


#hero .linear-gradient-div {
    background: linear-gradient(90deg, #B9BBC2 81.24%, rgba(182, 185, 193, 0) 100.98%);
    position:absolute;
    left:0;
    width: 45%;
    min-height:100%;
    z-index:2;
}

#hero .text-col {
    position:absolute;
    height:fit-content;
    width:40%;
    left:15%;
    top:0;
    bottom:0;
    margin:auto;
    min-height:50%;
    background: rgba(21, 40, 63, 1);
    color:white;
    z-index:3;
    padding: 2.5em;
}

.col.text-col h1 {
    margin-bottom: 1em;
}

.col.text-col a {
    text-decoration: none;
    color: black;
    padding:0.5em 1em;
    background: rgba(255, 141, 10, 1);
}

#hero .phone-img {
    display: none;
}

.col.text-col a:hover {
    text-decoration: none !important;
    color: black !important;
    padding:0.5em 1em;
    background: rgba(255, 141, 10, 1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.home-back-to-top {
    margin-bottom: 2em;
}

.whatis-paf-content a {
    border-bottom: 2px solid #EB7100;
    text-decoration: none;
    color: #02060f !important;
}

.whatis-paf-content a :hover {
   color:  #EB7100 !important;
}

.home-dialog-note p {
    margin-top: 3%;
    margin-left: 5%;
}

@media(max-width: 1400px) {
    .whatis-paf-content {
        padding: 2%;
        padding-left: 3%;
        padding-right: 0;
    }

    .whatis-paf-content a {
        border-bottom: 2px solid #EB7100;
        text-decoration: none;
        color: #02060f !important;
    }
    
    .whatis-paf-content a :hover {
       color:  #EB7100 !important;
    }
}

@media(max-width: 1200px) {
    .whatis-paf-content {
        padding: 0;
        padding-top: 1%;
        padding-left: 3%;
        padding-right: 0;
    }
    .whatis-paf {
        padding-top: 2em;
        padding-bottom: 2em;
    }

    .whatis-paf-content .p-button {
        height: inherit;
        width: inherit;
        margin-top: 1em;
    }
    .whatis-paf-content .content h2 {
        font-size: 24px;
        line-height: 24px;
    }

    .whatis-paf-content .content p {
        line-height: 22px;
    }

    .whatis-paf-content a {
        text-decoration: none;
        color: #02060f !important;
    }
    
    .whatis-paf-content a:hover {
       color:  #EB7100 !important;
    }
}


@media(max-width: 992px) {
    /* .hero {
        position: relative;
        width: 100%;
    }
    .hero .hero-image {
        width: 100%;
        position: relative;
        background-origin: border-box;
        background-size: 138%;
        height: auto;
        background-position: initial;
    }

    .hero .gradient-container {
        width: 100%;
        background: none;
    }

    .hero .linear-gradient {
        background: none;
        width: 100%;
        position: relative;
    }

    .hero .intro-text-container {
        position:relative;
        margin-top: 0;
        left: 0;
        text-align: center;
        width: 100%;
    } */

    .home-image {
        position: relative;
        padding-top: 56.25%;
    }

    .whatis-paf-content {
        padding: 2%;
        padding-left: 3%;
        padding-right: 0;
    }

    .home-options-row ul li:nth-child(2), .home-options-row ul li:nth-child(3) {
        margin-top: 2em;
    }
    button.p-dialog-header-icon.p-dialog-header-close.p-link {
        margin-right: 2em;
    }

    .dialog-details {
        width: 90%;
        height: auto;
    }

    #hero {
        background: none !important;
    }
    #hero .row {
        display: flex;
        flex-direction: column-reverse;
    }

    #hero .row .linear-gradient-div {
        display: none;
    }

    #hero .text-col, #hero .img-col {
        position: relative !important;
        width: 100% !important;
        height: 100%;
        z-index: 0;
        left:0;
        top:0;
        text-align: center;
    }

    #hero .img-col {
        background: none;
        position: relative;
        padding-top: 84.6%;
    }

    #hero .phone-img {
        display: inline;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: auto;
    }

    #hero .text-col h1.title-text {
        font-weight: 500;
        font-size: 25px;
        line-height: 32px;
    }

    .hero.container-fluid {
        min-height:auto;
        height: auto;
    }
}
.trailer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
}
.trailer.active
{
    visibility: visible;
    opacity: 1;
}
.trailer video {
    position: absolute;
    max-width: 500px;
    outline: none;
    display: block;
    margin: 0 auto;
}
/* .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    filter: invert(1);
    max-width: 18px;
} */
.dialog-header {
    padding-top: 52px;
}
.dialog-close {
    border-radius: 50%;
}
.dialog-close .pi {
    font-size: 1.25rem;
}
.dialog-close .p-button:focus {
    box-shadow: 0 0 0 0.2rem #0466E7 !important;
}
.dialog-btn-close  {
    position: absolute !important;
    right: -15px;
    top: 10px;
    opacity: 1;
}
.btn-close{
    filter: invert(1);
    position: relative;
    right: -240px;
    top: -178px;
    opacity: 1;
    max-width: 18px;
}
@media (max-width: 991px) {
    .trailer video {
        position: absolute;
        max-width: 500px;
        outline: none;
        display: block;
        margin: 0 auto;
    }

    .btn-close{
        filter: invert(1);
        position: relative;
        right: -238px;
        top: -174px;
        opacity: 1;
        padding: 29.25em 1.25em;
    }
}
@media (max-width: 512px) {
    .trailer video {
        /* max-width: 90%; */
        position: absolute;
        max-width: 264px;
        outline: none;
        display: block;
        margin: 0 auto;
    }

    .btn-close{
        filter: invert(1);
        position: relative;
        right: -122px;
        top: -105px;
        opacity: 1;
        padding: 29.25em 1.25em;
    }
}
.p-button-secondary {
    color: #6c757d !important;
}