.logo {
  height: 3.75rem;
}

.tabs {
  display: flex;
}
.card {
  width: 100%;
  border: 0;
}

.tabs .card {
  border: 0;
}

.contactCard {
  margin-top: 1.5em;
  display: flex;
  position: absolute;
  right: 3em;
  color: #ffffff;
  font-size: 19px;
  /* font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 24px; */
}

.card .p-tabmenu-nav {
  background-color: #15283f;
}

.card .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background-color: #15283f;
  color: #ffffff;
  /* height: 5em;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px; */
}

.card .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link :focus {
  background-color: #15283f;
  color: #ffffff;
}

.card .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link :hover {
  background-color: #15283f;
  color: #ffffff;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-text {
  padding-bottom: 0.35em;
  border-bottom: 2px solid rgba(237, 33, 124, 1);
}

.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
  .p-menuitem-link {
  background: none;
  border: 0;
  color: #ffffff;
}

.p-tabmenu .p-tabmenu-nav {
  border: 0;
}

.p-tabmenu
  .p-tabmenu-nav
  .p-tabmenuitem
  .p-menuitem-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  margin: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:first-child {
  display: none;
}

ul.p-tabmenu-nav.p-reset {
  height: 5em;
  padding: 1em 1em 1em 1em;
}

ul.p-tabmenu-nav.p-reset .p-menuitem-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
}

.card a:hover {
  color: #ffffff !important;
}

.nav-item a:hover {
  border-bottom: 2px solid #EB7100;
}

/* p-tabmenu	Container element.
p-tabmenu-nav	List element of headers.
p-tabmenuitem	Menuitem element.
p-menuitem-link	Link inside a menuitem.
p-menuitem-text	Label of a menuitem.
p-menuitem-icon */

.styled-burger {
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.styled-burger div {
  width: 2rem;
  height: 0.25rem;
  background: rgba(21, 40, 63, 1);
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.transform-first {
  transform: rotate(45deg);
}

.transform-second {
  opacity: 0;
  transform: translateX(0);
}

.transform-third {
  transform: rotate(-45deg);
}

.styled-burger > div + div {
  margin-top: 6px;
}

.main-menu {
  height: 4em;
  background: rgba(21, 40, 63, 1);
  color: #ffffff;
  transition: all 0.3s linear;
}

.main-menu .nav-item {
  margin: 0 0.5em;
  flex-shrink: 0;
}

.main-menu .nav-item a {
  text-decoration: none;
  color: #ffffff;
}

.main-menu .nav-item.active {
  border-bottom: 2px solid rgba(237, 33, 124, 1);
}

.logo a {
  width: 100%;
  display: block;
}

.menu-contact-us-text span a {
  text-decoration: none;
  border-bottom: 2px solid #EB7100;
  color: #2F323A !important;
  white-space: nowrap;
}

.notification-bar {
  background-color: #eaedec;
  padding: 10px;
}

.notification-bar p {
  font-size: 14px;
  /* font-weight: bold; */
}

.notification-bar a {
  text-decoration: none;
  border-bottom: 2px solid #EB7100;
  color: #2F323A !important;
  white-space: nowrap;
}

.language-toggle {
  display: none;
  margin-left: auto;
  width: fit-content;
}

.menu-language-toggle {
  display: none; 
  margin: auto;
  width: fit-content;
}

.notification-bar i {
  vertical-align: middle;
  margin-right: 5px;
  color: #EB7100;
}

@media (max-width: 1400px) {
  .main-logo {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .hide {
    display: none !important;
  }
  .menu-language-toggle {
    display: block; 
  }

  .main-menu.navbar-expand {
    flex-direction: column;
    min-height: 12em;
  }

  .menu-contact-us-text.pe-5 {
    padding-right: 0 !important;
  }

  .main-menu.navbar-expand .navbar-nav {
    flex-direction: column;
  }

  .main-menu {
    background: #ffffff;
  }

  .main-menu {
    color: rgba(21, 40, 63, 1);
  }

  .main-menu .nav-item {
    margin: 0;
    text-align: center;
  }

  .main-menu .nav-item a {
    text-decoration: none;
    color: rgba(21, 40, 63, 1);
  }

  .main-menu .nav-item a:hover {
    color: rgba(21, 40, 63, 1) !important;
  }

  .main-menu .nav-item.active {
    border-bottom: 0;
  }

  .menu-contact-us-text {
    text-align: center;
    margin: 0 auto !important;
  }
  .whatis-paf-content .content {
    margin: 0;
  }

  .main-logo {
    width: 70%;
  }
}

@media (min-width: 1025px) {
  .styled-burger {
    display: none;
  }
  .language-toggle {
    display: block;
  }

  .menu-contact-us-text {
    text-align: end;
    margin-left: auto;
  }

  .main-menu {
    display: flex !important;
  }

  .menu-contact-us-text span a {
    text-decoration: none;
    color: rgb(255, 141, 10) !important;
  }

  .hide {
    display: flex !important;
  }

  .main-menu {
    height: 4em;
    background: rgba(21, 40, 63, 1);
    color: #ffffff;
  }

  .main-menu.navbar-expand {
    flex-direction: row;
    min-height: 4em;
  }

  .main-menu.navbar-expand .navbar-nav {
    max-width: 75%;
    flex-flow: wrap;
  }

  .main-menu .nav-item {
    margin: 0 0.5em;
    text-align: start;
  }

  .main-menu .nav-item a {
    text-decoration: none;
    color: #ffffff;
  }

  .main-menu .nav-item.active {
    border-bottom: 2px solid rgba(237, 33, 124, 1);
  }
}

@media (max-width: 576px) {
  .bms-logo {
    width: 90%;
  }
  .notification-bar p {
    font-size: 12px;
  }
}
