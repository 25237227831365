.read-more {
  /* background-color: #107F4D; */
  min-height: 5em;
  color: #ffffff;
  padding: 1.4em 0 1em 0;
  text-align: center;
  /* margin-bottom: 0.2em; */
}

.read-more-link a {
  color:#ffffff;
  border-bottom: 2px solid #EB7100;
  text-decoration: none;
}

.read-more-link a:hover {
  color:#EB7100;;
}

.read-more .readmore-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
}

.read-more-row {
  background-color: #107F4D;
}

