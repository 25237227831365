.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.aboutus .container > div + div {
    margin-top: 2em;
}

.aboutus-content ul {
    list-style-type: circle;
    justify-content: center;
    margin-top: 1.5em;
}

.aboutus-content {
    justify-content: center;
}

.aboutus-content h2 {
    color: #3E000C;
}

.important-note {
    background-color: rgba(186, 195, 191, 0.3);
    min-height: 23em;
    position: relative;
}

.aboutus-content li a {
    text-decoration: none;
    color: #2F323A;
}

.important-note .pink-bar {
    width: 0.7%;
    position: relative;
    float: left;
    background: #ED217C;
    height: 100%;
}

.important-note .main-content {
    width: 95%;
    height: 100%;
    margin-left: 3em;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-right: 5em;
}

.main-content p{
   margin-top: 1em;
}

.main-content h3 {
    color: #3E000C;
}

.main-content .custom-list {
    margin-top: 1em;
}

.aboutus-header {
    height: 16.75em;
    width: 100%;
    background-image: url("../../Images/aboutus-image.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.aboutus-header .overlay {
    position: absolute;
    height: 16.75em;
    width: 100%;
    background-color: rgba(21, 40, 63, 0.5);
}

.aboutus-header .banner-text-div {
    position: relative;
    width: 100%;
}

.aboutus-header .banner-text {
    color: #FFFFFF;
}

.aboutus .container {
    margin-top: 3em;
}

.bms-assistance .asst-body {
    margin-bottom: 2em;
}

.aboutus-content h2 {
    margin-top: 1em;
}

.aboutus-content video {
    position: relative;
    max-width: 500px;
    outline: none;
    display: block;
    margin: 0 auto;
}

.aboutus-content video:focus {
    box-shadow: 0 0 0 0.1rem #000000 !important;
}

.custom-list {
    margin-top: 2em;
}

.important-note .icon-container {
    position: absolute;
    height: 10em;
    width: 10em;
    background-image: url('../../Images/aboutIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 17em;
    opacity: 0.3;
    left: 46.4em;
}

.important-note::after {
    content: "";
    background-color: rgba(186, 195, 191, 0.3);
    background-image: url('../../Images/aboutIcon.svg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto 60%;
    opacity: 0.5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}


.contact-wrap a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color:  #2F323A !important;
    white-space: nowrap;
}

@media(max-width:992px) {
    .important-note::after {
        background-size: auto 23%;
    }

    .aboutus-header {
        background-image: url("../../Images/about-us-phone.svg");
    }

    .aboutus h1.banner-text {
        font-weight: 300;
        font-size: 28px;
        line-height: 32px;
    }

    .aboutus-content p.body-large {
        font-size:17px;
        line-height: 141%;
    }

    .aboutus-content video {
        position: relative;
        max-width: 90%;
        outline: none;
        display: block;
        margin: 0 auto;
    }

    .important-note .main-content {
        width: auto;
        margin-left: 1em;
        padding-right: 1em;
    }
    
}

/* @media(max-width:1200px) {
    .contact-wrap {
        display: inline;
    }
} */



