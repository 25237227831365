.medicines-header {
    height: 16.75em;
    width: 100%;
    background-image: url("../../Images/medicines-banner.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.medicines-header .banner-text-div {
    position: relative;
    width: 100%;
}

.medicines-header .banner-text {
    color: #FFFFFF;
}


.medicines-header .overlay {
    position: absolute;
    height: 16.75em;
    width: 100%;
    background-color: #15283F;
    opacity: 0.5;
}

.medicine-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 32px;
    margin-top: 3em;
}

/* .medicines-main-content .read-more {
    margin-top: 3em;
} */

.medicines-main-content .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}

.restricted {
    font-size: 15px;
    margin-top: 1.5em;
    margin-bottom: 2em;
}

.medicines-main-content .medicines-list {
    columns: 2;
    margin-top: 3em;
}

.medicines-main-content .medicines-list .medicine-list-item {
    width: 100%;
    min-height: 7em;
    padding: 1em;
}

.medicine-list-item.color-me {
    background: rgba(241, 243, 242, 1);
}

.medicines-main-content .medicines-list .medicine-list-item .trade-name {
    width: 6.4em;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2F323A;
}

.medicines-main-content .medicines-list .medicine-list-item .drug-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #2F323A;
}

.medicines-content .row .donated-by {
    margin-top: 2em;
    margin-bottom: 1em;
}

.restricted a {
    text-decoration: none;
    border-bottom: 2px solid #EB7100;
    color:  #2F323A !important;
}

@media(max-width:992px) {
    .medicines-header {
        background-image: url("../../Images/medicines-phone-img.svg");
    }

    .medicines-header h1.banner-text {
        font-weight: 300;
        font-size: 28px;
        line-height: 32px;
    }
}

@media(max-width: 570px) {
    .medicines-main-content .medicines-list {
        columns: 1;
    }

    .medicines-main-content .medicines-list .medicine-list-item .trade-name,
    .medicines-main-content .medicines-list .medicine-list-item .drug-name {
        width: 100%;
        text-align: center;
    }

    .medicines-main-content .medicines-list .medicine-list-item {
        padding: 1.5em 1em;
        vertical-align: middle !important;
        display: flex;
        flex-direction: column;
    }

    .medicines-main-content .medicines-list .medicine-list-item:nth-child(even) {
        background: rgba(241, 243, 242, 1);
        
    }

    ul.medicines-list li.color-me {
        background: none;
    }
}

@media(max-width: 1400px) {
    .medicines-main-content .medicines-list .medicine-list-item {
        min-height: 9em;
    }
}
@media(max-width: 992px) {
    .medicines-main-content .medicines-list .medicine-list-item {
        min-height: 11em;
    }
}
.trade-name1 {
    width: 10.4em;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2F323A;
}
