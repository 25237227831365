.font-face-p {
  font-family: "Poppins";
}

body {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  overflow: auto;
}

.active {
  overflow: hidden;
}

h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
}

h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
}

h4 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

p {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.plain-list {
  padding-left: 0;
}

.plain-list li {
  color: #2F323A;
  list-style: none;
  background-position: 0 6px;
  background-size: 10px;
  background-repeat: no-repeat;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.plain-list li + li {
  margin-top: 1em;
}

.custom-list {
  padding-left: 0;
}

.custom-list li {
  color: #2F323A;
  list-style: none;
  background-image: url("./Images/li-icon.svg");
  background-position: 0 6px;
  padding-left: 1em;
  background-size: 10px;
  background-repeat: no-repeat;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
}

.custom-list li + li {
  margin-top: 1em;
}

.body-large {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 152%;
}

span.underline {
  border-bottom: 2px solid #EB7100;
}

.body-bold {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 133%;
}

b, strong {
  font-weight: bolder;
}

a:hover {
  color: #EB7100 !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0.1rem #000000 !important;
}

.container-fluid {
  padding: 0;
}

.bold-span {
  font-weight: bolder;
}

.p-button:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}

.title-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
}

.ot-sdk-show-settings {
  display: none !important;
}
.ot-sdk-btn-floating {
  display: none !important;
}
.ot-floating-button {
  display: none !important;
}