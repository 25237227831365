.eligibility-assessment h1 {
   text-align: center;
   color: #15283F;
   padding: 1em 1em 0.5em 1em !important;
}


.assessment-content {
    text-align: center;
    margin-top: 2em;
}

.assessment-body.container .btn-container {
    margin-top: 2em;
}

.assessment-body.container .btn-container .p-button.p-component.p-button-outlined {
    text-align: center;
}

.question-one > div {
    margin: 0 auto;
}
.question-one p {
    font-family: Poppins;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    color: #70877F;
    text-align: center;
    margin: 1em 1em 1em 2.5em;
}

.question-one h1 {
    text-align: center;
    color: #15283F;
    padding: 1em 1em 0.5em 1em !important;
}

.question-one h2 {
    font-family: Poppins;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    color: #3E000C;
    margin-top: 0.4em;
    margin-bottom: 1em;
}
 
.continue-previous-container {
    text-align: center;
}

.assessment-body.container .btn-container .p-button.p-component.p-button-outlined.p-button-help {
    width: 6.25em;
    height: 3.5em;
    background: rgba(255, 141, 10, 1);
    margin: 0 auto;
    border: none;
    display: block;
    border-radius: 0em;
}

.assessment-body.container .btn-container .p-button-label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color : black;
}

.answer-box .answer-option-one {
    width: 48em;
    height: 3.5em;
    margin: 0 auto;
    display: block;
    text-align: center;
}

.answer-box .answer-option-two {
    width: 100%;
    margin-bottom: 1em;
    display: block;
}

.answer-box .answer-option-two > span {
    width: 15%;
    display: inline-block;
    margin-left: 0.5em;
}


/* IncrementDecrement Component Style */

.increment-decrement > button, .increment-decrement > div {
    display: inline-block;
}

.increment-decrement > button {
    width: 3em;
    height: 3em;
    border-radius: 3.5em;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 75%;
    border: 1px solid rgba(186, 195, 191, 1);
    background: rgba(255, 255, 255, 1);
}

/* .assessment-main-div .read-more {
    margin-top: 3em;
} */

.assessment-main-div .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}

.answer-option-one .p-dropdown {
    min-width: 30%;
}

/* .question-box .read-more {
    margin-top: 3em;
} */

.question-box .read-more-row {
    margin-top: 3em;
    margin-bottom: 0.25em;
}

.continue-previous-container .continue-btn, .continue-previous-container .previous-btn {
    height: 3.5em;
    margin-top: 2em;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2F323A;
    background: #BAC3BF;
}

.continue-previous-container .continue-btn {
    color: rgba(47, 50, 58, 0.6);

}

.continue-previous-container .previous-btn {
    background: none;
}

.continue-previous-container .previous-btn .previous-text {
    width: 4.9em;
    height: 1.5em;
    border-bottom: 2px solid #EB7100;
}

.continue-previous-container .continue-btn.active {
    background: #EB7100;
    color: rgba(47, 50, 58, 1);
    
}

.continue-previous-container .continue-btn.active:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
}


.answer-box .answer-option-one .p-dropdown-trigger-icon {
    color: #EB7100;
}

.answer-option-two {
    text-align: center;
}

.answer-option-two button {
    height: 3.5em;
    background: #ffffff;
    border: 1px solid #BAC3BF;
    box-sizing: border-box;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
}

.answer-option-two button:last-child {
    margin-left: 1.75em;
}

.answer-option-two button.active {
    background: rgba(255, 141, 10, 0.3);
    border: 1px solid #EB7100;
}

.answer-option-three {
    text-align: center;
    position: relative;
    margin-bottom: 1em;
}

.increment-decrement .decrement-counter {
    width: 48px;
    height: 48px;
}

.increment-decrement .increment-btn {
    width: 48px;
    height: 48px;
}

.question-one.container-fluid .description-text p {
    color: #2F323A;
}

.tool-tip-div .custom-target-icon .p-tooltip-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.question-box-div {
    text-align: center;
}

.main-question-box .text-center {
    color: #70877F ;
}

.question-text h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    color: #3E000C;

}

span#integeronly {
    position: relative;
}

span#integeronly input {
    padding-left:25px;
}

span#integeronly:before {
    position: absolute;
    content:"$";
    margin-top: auto;
    top: 8px;
    left: 10px;
}

.p-dropdown-panel.p-component {
    width: 20% !important;
}

.p-dropdown-panel.p-component .p-dropdown-item {
    width: 100%;
}

.p-dropdown-item {
    white-space: normal;
}

.p-tooltip.p-tooltip-bottom {
    width: 27em;
    height: 12.5em;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-text {
    width: 27em;
    height: 12.5em;
    background: #15283F;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
    padding: 1.5em;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-text a {
    text-decoration: none;
    color: rgb(202, 95, 95);
}

.question-text h2 {
    display: inline-block;
}

@media(max-width: 770px) {
    button.px-5 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    span.option-text.px-5 {
        padding-right: 2rem !important;
        padding-left:2rem !important;
    }

    button.me-4 {
        margin-right: 1rem !important;
    }
}

@media(max-width:760px) {
    .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
        width:80%;
    }
    .p-dropdown-panel.p-component {
        width:80%;
    }

    .question-text h2 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 23px;
        line-height: 130%;
        margin-right: 0 !important;
    }

    .question-one h1 {
        font-family: Poppins;
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 114%;
    }

    .main-question-box p {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 160%;
    }

    .answer-option-two button {
        width: 156px;
        height: 56px;
        padding: 16px 64px !important;
    }

    .answer-option-two > button.me-4 {
        margin-right: 0 !important;
    }

    .answer-option-two > button + button {
        margin-left: 20px;
    }

    .answer-option-two .option-text.px-5 {
        padding: 0 !important;
    }

    .continue-previous-container button {
        width: 133px;
        height: 56px;
    }

    .continue-previous-container button.active:hover {
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }
}

@media(max-width: 539px) {
    .p-dropdown-panel.p-component {
        left: 13% !important;
    }

    .tool-tip-div {
        margin-top: 0.5em;
        text-align: center;
    }

    .p-tooltip.p-tooltip-bottom {
        width: 90% !important;
        height: auto !important;
        left: 20% !important;
    }

    .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
        left: 54% !important;
    }

    .p-tooltip.p-tooltip-bottom .p-tooltip-text {
        width: 120% !important;
        height: auto !important;
        left: 0 !important;
    }
}
