.circle-outline {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #fff;
  border: 2px solid #3e000c;
  color: #3e000c;
  text-align: center;
  display: inline-block;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
}

.circle-outline + h2 {
  display: inline-block;
  width: 90%;
  margin-left: 1em;
}

.howto-header {
  height: 16.75em;
  width: 100%;
  background-image: url("../../Images/apply-banner.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}

.howto-header .overlay {
  position: absolute;
  height: 16.75em;
  width: 100%;
  background-color: #15283f;
  opacity: 0.5;
}

.howto-header .banner-text-div {
  position: relative;
  width: 100%;
}

.howto-header .banner-text {
  color: #ffffff;
}

.how-to-apply .apply.container {
  margin-top: 3em;
}

.apply.container > div + div {
  margin-top: 0.5em;
}

/* .apply.container div {
  border-bottom: 1px solid #BAC3BF;
  padding-bottom: 1em;
} */

.apply.container div:last-child {
  border-bottom: 0;
}

.apply.container h2 {
  color: #3e000c;
  font-style: normal;
  line-height: 24px;
  font-weight: 600;
  font-size: 20px;
}

.apply.container h2 + p {
  margin-top: 1em;
  margin-bottom: 1.5em;
  margin-left: 3.5em;
}

.underline.body-bold a {
  text-decoration: none;
  color: #2f323a;
}

.apply-title .title-string {
  font-weight: normal;
  font-size: 21px;
  line-height: 152%;
  margin-bottom: 2em;
}

.apply-title .title-string .body-bold {
  font-size: 21px;
}

.apply-title p .underline.body-bold {
  line-height: 152%;
}

/* .how-to-apply .read-more {
  margin-top: 3em;
} */

.how-to-apply .read-more-row {
  margin-top: 3em;
  margin-bottom: 0.25em;
}

.pointer-one {
  margin-left: 3.5em;
}

.field-container {
  margin-left: 3.5em;
  margin-top: 2em;
}

.field-container .p-dropdown {
  border: 1px solid #828282;
  width: 75%;
}

.field-container .p-dropdown-trigger-icon {
  color: #EB7100;
}

.reach-us {
  width: 100%;
  height: 17em;
  position: relative;
  background: rgba(186, 195, 191, 0.3);
  margin-top: 3em;
}

.reach-us .pink-bar {
  width: 6px;
  position: relative;
  display: inline-block;
  background: #ed217c;
  height: 17em;
}

.reach-us .reach-us-content {
  width: 97%;
  float: right;
  padding: 0.75em;
}

.reach-us-content p span {
  display: block;
}

.download-application-button .p-button {
  background-color: #EB7100 !important;
  border: none !important;
  min-height: 3em;
  width: 100%;
  border-radius: 0em;
}

.download-application-button
  .p-button
  .p-component
  .p-button-outlined
  .p-button-help
  .p-disabled:disabled {
  color: rgba(47, 50, 58, 0.6);
  background-color: #bac3bf;
}

.download-application-button .p-button .p-button-label {
  color: black;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.apply-contact-us .contact-us .icon-container {
  left: 29em;
  top: 6.3em;
}

.field-container .body-bold {
  margin-bottom: 1em;
}

.download-application-button span {
  width: 35%;
  display: inline-block;
}

.download-application-button .call-fax {
  margin-left: 2em;
  width: 55%;
  padding-right: 2em;
}

.download-application-button {
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.apply-contact .contact-us {
  margin-top: 2em;
  margin-bottom: 2em;
}

.apply-contact .contact-us .contact-content {
  padding: 2.5em;
}

.circle-pointer {
  display: inline-block;
}

.call-fax a {
  text-decoration: none;
  border-bottom: 2px solid #EB7100;
  color: #2f323a !important;
  white-space: nowrap;
}

.call-fax .bold-span {
  display: contents;
}

.download-form {
  width: 700px;
}

/* .download-form.p-dialog .p-dialog-header { */
/* border-bottom: 1px solid #d0d0d0; */
/* } */

.btn-flex-group {
  display: flex;
  height: 20vh;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 30px;
}

.radio-group {
  padding-top: 20px;
}

.radio-subgroup {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
}

.radio-subgroup .field-text a {
  text-decoration: none;
  border-bottom: 2px solid #EB7100;
  color: #2f323a !important;
  white-space: nowrap;
}

.radio-subgroup:last-child {
  margin-bottom: 0;
}

.radio-subgroup h2 {
  margin-bottom: 6px;
  font-size: 18px;
}

.radio-group .field-radiobutton:first-child {
  margin-right: 70px;
}

.radio-group label {
  font-size: 16px;
}

.btn-flex-group .p-button.p-button-help.p-button-outlined {
  background-color: #EB7100;
  border: none;
  border-radius: 0;
  color: #000;
  width: 47%;
}

.field-radiobutton {
  display: flex;
  align-items: baseline;
}

.btn-flex-group .p-button.p-button-help.p-button-outlined:first-child {
  margin-right: 25px;
}

.info-list ul {
  list-style: disc;
  margin-left: 20px;
  margin-top: 25px;
}

.info-list ul li p {
  font-size: 14px;
}

.info-list ul li {
  margin-bottom: 7px;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.btn-group .p-button-outlined.btn-secondary {
  margin-right: 15px;
  background-color: #e0e0e0 !important;
  border: none !important;
  border-radius: 0;
  color: #000;
  width: 150px;
}

.btn-group .p-button-outlined.btn-primary {
  background-color: #EB7100 !important;
  border: none !important;
  border-radius: 0;
  color: #000 !important;
  width: 150px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #828282;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: #f6cb99;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #EB7100;
}

.p-radiobutton .p-radiobutton-box.p-highlight,
.p-radiobutton .p-radiobutton-box.p-highlight:hover,
.p-radiobutton .p-radiobutton-box.p-highlight:active,
.p-radiobutton .p-radiobutton-box.p-highlight:focus-visible,
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #EB7100 !important;
  background: #EB7100 !important;
  box-shadow: none !important;
  outline: unset !important;
}

@media (max-width: 1400px) {
  .download-application-button .p-button {
    min-height: 4.2em;
  }
  .download-application-button .call-fax {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .howto-header {
    background-image: url("../../Images/how-to-apply-phone.svg");
  }
  .download-application-button .p-button {
    min-height: 3em;
  }
  .download-application-button span:first-child {
    width: 80%;
  }
  .download-application-button .call-fax {
    width: 80%;
    margin-left: 0;
    margin-top: 0.7em;
    padding-right: 0;
  }

  .apply.container h2 + p,
  .pointer-one,
  .field-container {
    margin-left: 0.75em;
  }

  .btn-flex-group {
    height: auto;
  }

  .radio-group {
    height: auto;
    flex-wrap: wrap;
  }

  .btn-flex-group .p-button.p-button-help.p-button-outlined {
    width: 100%;
  }
  .btn-flex-group .p-button.p-button-help.p-button-outlined:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 570px) {
  .pointer-one {
    margin-left: 0.5em;
  }
  .field-container {
    margin-left: 0.5em;
  }
  .contact-wrap-apply {
    display: block;
  }
  .download-form {
    width: 96vw;
  }
  .radio-group label {
    font-size: 15px;
  }
  .info-list ul li p {
    font-size: 14px;
  }
  .radio-group .field-radiobutton:first-child {
    margin-bottom: 20px;
  }
}
